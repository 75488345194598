import React from 'react';
import PageWithHeader from '../components/pageWithHeader';
import style from './holiday2021.module.scss';

const Holiday2020 = () => {

  return (
    <PageWithHeader
      title="Holiday 2020"
      description="Holiday2020">
      <article className={style.mainArticle}>
        <iframe src="https://player.vimeo.com/video/493863119" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
      </article>
    </PageWithHeader>
  );
};

export default Holiday2020;